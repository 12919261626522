import { Box, Grid } from '@mui/material'
import styled from '@emotion/styled'
import { Link, useLocation } from 'react-router-dom'
import { INFO_COLOR, LOGO_CRANIAL_URL, MAX_MOBILE_DIMENSIONS } from '../app/constants'

const StyledHeader = styled.header`
  background: #fff;
  box-shadow: 0 2px 4px rgb(0 0 0 / 25%);
  padding: 15px;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    padding: 30px;
  }
`

const Header = () => {
  const location = useLocation()

  return (
    <StyledHeader>
      <Grid
        container
        justifyContent="center"
      >
        <HeaderWrapper>
          <Grid item />
          <Grid item>
            <CenteredBox>
              <Box
                alt="logo"
                component="img"
                src={LOGO_CRANIAL_URL}
                width="100%"
              />
            </CenteredBox>
          </Grid>
          <FlexEndGrid item>
            {location.pathname === '/' ? (
              <ChangeAppointmentLink
                to="/reschedule"
              >
                I need to change an existing appointment
              </ChangeAppointmentLink>
            ) : null}
          </FlexEndGrid>
        </HeaderWrapper>
      </Grid>
    </StyledHeader>
  )
}

const HeaderWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;
  column-gap: 1rem;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {
    width: 100%;
    grid-template-columns: 1fr 10rem 1fr;
    grid-column-gap: 2rem
  }
`

const FlexEndGrid = styled(Grid)`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;

  @media (min-width: ${MAX_MOBILE_DIMENSIONS}px) {    
    justify-content: flex-end;
    text-align: end;
  }
`

const ChangeAppointmentLink = styled(Link)`
  color: ${INFO_COLOR};
`

const CenteredBox = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
`

export default Header
