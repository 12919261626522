import { Box, CircularProgress, Typography } from '@mui/material'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import dayjs from 'dayjs'
import { useMutation } from 'react-query'
import axios from 'axios'
import { DayPicker, TimePicker } from './form'
import {
  getWeekDates,
  getMonthYear,
  groupTimesByDate,
  useAppoinments,
} from './utils'
import { DATE_PATTERNS, ERROR_MESSAGE } from '../../app/constants'
import AppBar from '../../components/AppBar'
import { setAppointmentInfo } from '../../store/appointmentInfo'
import NextButton from '../../components/NextButton'
import ViewContainer from '../../components/ViewContainer'
import BackButton from '../../components/BackButton'
import useDialog from '../../hooks/dialog'
import Dialog from '../../components/Dialog'

type RescheduleProps = {
  ServiceCenterID: string;
  AppointmentDateTime: string;
  appointmentList: any;
  personID: string;
}

const AppointmentSelector = () => {
  const alertHook = useDialog()
  const reschedule = useLocation().state?.reschedule ?? false
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const ServiceCenterID = useSelector((state: any) => state.appointmentInfo.ServiceCenterID)
  const appointmentList = useSelector((state: any) => state.identifyPatient.appointmentList)
  const personID = useSelector((state: any) => state.reschedule.personID)
  const appointmentDuration = useSelector(
    (state: any) => state.appointmentDuration.appointmentDuration,
  )
  const initialDate = dayjs()
  const {
    appointments, date, loading, setDate,
  } = useAppoinments(
    initialDate,
  )

  const [availableTimes, setAvailableTimes] = useState<{
    [key: string]: string[];
  }>({})
  const [monthHeader, setMonthHeader] = useState<string>('')
  const [selectedDate, setSelectedDate] = useState<string>('')
  const [selectedTime, setSelectedTime] = useState<string>('')
  const nextButtonText = reschedule ? 'Reschedule' : 'Next'
  const RESCHEDULE_URL = `${process.env.REACT_APP_BASE_URL}/api/reschedule-appointment`

  const mutation = useMutation<unknown, unknown, RescheduleProps, unknown>({
    mutationFn: async (data: any) => axios.post(RESCHEDULE_URL, data),
    onSuccess: () => {
      navigate('/confirm-reschedule')
    },
    onError: (e: any) => {
      if (e.response?.status === 409) {
        alertHook.setDialog({
          title: 'Slot unavailable',
          description: 'This slot was taken by someone else, please choose another one',
        })
      } else {
        navigate('/contact-form', { state: { messageState: ERROR_MESSAGE, error: `reschedule-appointment: ${e.message}` } })
      }
    },
  })

  useEffect(() => {
    const availTimes = groupTimesByDate(appointments)
    setAvailableTimes(availTimes)
    const availableTimesList = Object.entries(availTimes).sort()
    // Set first available date as selected date.
    setSelectedDate(
      availableTimesList.length > 0 ? availableTimesList[0][0] : '',
    )
  }, [appointments])

  useEffect(() => {
    // If selectedDate is not in the current week, reset selectedDate.
    const currentWeekDates = getWeekDates(date).map((val) => (
      val.format(DATE_PATTERNS.iso_8601_date)
    ))
    if (!currentWeekDates.includes(selectedDate)) {
      setSelectedDate('')
    }
    setMonthHeader(getMonthYear(currentWeekDates))
  }, [date])

  const handleOnClickBack = (event: any) => {
    event.preventDefault()
    navigate(-1)
  }

  const handleOnFindClinic = (event: any) => {
    event.preventDefault()
    navigate('/find-clinic')
  }

  const handleReschedule = async () => {
    const appointmentDateTime = dayjs(`${selectedDate} ${selectedTime}`).format(
      DATE_PATTERNS.iso_8601_date_time,
    )
    // Search for appointment in appointments
    const appointment = appointments.find(
      (val) => val.AppointmentDateTime === appointmentDateTime,
    )

    if (!appointment) {
      navigate('/contact-form', { state: { messageState: ERROR_MESSAGE, error: 'reschedule-appointment: No appointment found' } })
    }

    await mutation.mutateAsync({
      AppointmentDateTime: appointmentDateTime,
      ServiceCenterID,
      personID,
      appointmentList,
    })
  }

  const handleSubmit = () => {
    // selectedDate is in YYYY-MM-DD format
    // selectedTime is in HH:mm format
    const appointmentDateTime = dayjs(`${selectedDate} ${selectedTime}`).format(
      DATE_PATTERNS.iso_8601_date_time,
    )
    // Search for appointment in appointments
    const appointment = appointments.find(
      (val) => val.AppointmentDateTime === appointmentDateTime,
    )
    if (appointment) {
      dispatch(setAppointmentInfo(appointment))
      navigate('/details-appointment')
    }
  }

  const renderView = () => {
    if (loading) {
      return (
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress size={30} />
        </Box>
      )
    }

    return (
      <>
        <DayPicker
          availableTimes={availableTimes}
          date={date}
          monthHeader={monthHeader}
          selectedDate={selectedDate}
          serviceCenterID={ServiceCenterID}
          setDate={setDate}
          setSelectedDate={setSelectedDate}
          setSelectedTime={setSelectedTime}
        />
        {availableTimes![selectedDate] && (
          <TimePicker
            availableTimes={availableTimes!}
            selectedDate={selectedDate}
            selectedTime={selectedTime}
            setSelectedTime={setSelectedTime}
          />
        )}

        <DividingLine />

        <ButtonWrapper>
          <NextButton
            isDisabled={!selectedTime}
            isLoading={mutation.isLoading}
            onClick={reschedule ? handleReschedule : handleSubmit}
            secondary
            text={nextButtonText}
          />
          <BackButton onClick={reschedule ? handleOnClickBack : handleOnFindClinic} />
        </ButtonWrapper>
      </>
    )
  }

  return (
    <ViewContainer>
      <AppBar
        description="Please select your preferred date and time from the available options below.
        Note that days with open times for evaluations will be indicated by a blue circle."
        progress={3}
        title="Select a time and date for your evaluation"
      />
      <Typography
        fontWeight="bold"
        variant="caption"
      >
        *This appointment will take
        {' '}
        {appointmentDuration}
        {' '}
        minutes.
      </Typography>
      <Wrapper>{renderView()}</Wrapper>
      <Dialog
        dialogHook={alertHook}
      />
    </ViewContainer>
  )
}

const ButtonWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
`

const Wrapper = styled(Box)`
  text-align: center;
  max-width: 400px;
  padding: 1rem 0px 20px;
  margin-left: auto;
  margin-right: auto;
`

const DividingLine = styled(Box)`
  border-top: 1px solid #e8e8e8;
  margin: 35px 0px 20px;
`

export default AppointmentSelector
